import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import { getImage } from '../components/image'

const generate = () => {
  let arr = []
  for (let i = 0; i < 17; i++) {
    arr.push(getImage(i + 1))
  }
  return arr
}

const IndexPage = () => (
  <div
    style={{
      position: 'relative',
      margin: 0,
      left: 0,
      padding: 0,
      height: '100%',
      width: '100%',
      background: 'black',
    }}
  >
    <h1 style={{ paddingTop: 20, color: '#cf9802', textAlign: 'center' }}>
      🎉 Parabéns a Nós! - 6 Anos - 🎉
    </h1>
    <video
      id="us"
      autoplay
      playsinline
      muted
      controls
      width="100%"
      style={{ outline: 'none' }}
      preload="auto"
    >
      <source
        src="https://dl.dropbox.com/s/d89nbx9uyeimhyu/10495547_10154273929830175_1196740362_n.mp4?dl=1#t=3"
        type="video/mp4"
      />
      Your browser does not support the video tag.
    </video>
    {generate()}
  </div>
)

export default IndexPage

const style = {
  image: {
    width: '400px',
    height: '400px',
  },
}
